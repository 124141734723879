<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.add_new_bunk') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="close()">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="p-2" v-loading="loadingData">
      <filterSchedules />
      <schedulesCabinetFull @loadOn="loadOn()" @loadOff="loadOff()" ref="schedule"/>
    </div>
  </div>
</template>
<script>
import filterSchedules from "./../../schedulesCabinet/components/filter-schedules";
import schedulesCabinetFull from "./../../schedulesCabinet/components/schedulesCabinetFull";
import drawerChild from '@/utils/mixins/drawer-child'; 
import {mapActions, mapGetters} from 'vuex'
export default {
  mixins: [drawerChild],
  components: { filterSchedules, schedulesCabinetFull },
  data() {
    return {
      loadingData: false
    };
  },
  computed: {
    ...mapGetters({
      bunk : 'schedulesCabinets/bunk'
    })
  },
  methods: {
    ...mapActions({
         empty: 'schedulesCabinets/empty',
         delete: 'schedulesCabinets/destroy'
    }),
    afterOpened(){
      if(this.bunk.length != 0){
        this.$refs.schedule.scroll()
      } else {
        this.$refs.schedule.fetchAgain()
      }
    },
    loadOn(){
      this.loadingData = true
    },
    loadOff(){
      this.loadingData = false
    },
    closeDel() {
      if(this.bunk.length != 0){
        this.delete(this.bunk.id);
        this.empty();
      }
    },
    afterClosed(){
        if (this.bunk) {
          this.parent().loadBunk();          
        }
    },
  },
};
</script>